import React from "react"
import dynamic from "next/dynamic"
import Cookies from "js-cookie"

const AccordionLogin = dynamic(() => import("../accordions/accordion-login"), {
    ssr: false,
})

function RewardsNavigationMobile({ item, punchhUserCookie, setIsHamburgerOpen, isDesktop }) {

    const userSession = JSON.parse(Cookies.get("punchh_authenticated_user"))

    return (
        <>
            {!isDesktop && userSession && punchhUserCookie ? (
                <div className="px-5 py-2 bg-ocean-300 text-center">
                    <p className="text-xs text-white capitalize mb-0 font-noto">
                        Welcome {userSession.first_name}
                    </p>
                </div>
            ) : null }
            <AccordionLogin
                items={item.itemsCollection.items}
                active
                item={item}
                setIsHamburgerOpen={setIsHamburgerOpen}
                punchhUserCookie={punchhUserCookie}
            />
        </>
    )
}

export default RewardsNavigationMobile
