/* eslint-disable camelcase */
import React from "react"
import { uid } from "uid"
import dynamic from "next/dynamic"
import Cookies from "js-cookie"

import { useGlobalContext } from "../../context/layout-context"

import { RewardsMenu } from "../../utils/mockData"
import RewardsNavigation from "../navigation/rewards-navigation"
import RewardsNavigationMobile from "../navigation/rewards-navigation-mobile"

const ActiveLink = dynamic(() => import("./active-link"))

function LogOutAccountButton({ button, classList, setIsHamburgerOpen }) {
  const { globalState } = useGlobalContext()
  const { isDesktop } = globalState || {}
  const punchhUserCookie = Cookies.get("punchh_authenticated_user")

  return (
    <React.StrictMode>
      {!punchhUserCookie ? (
        <div className="px-5">
          <ActiveLink
            href={`${button.slugUrl}/`}
            setIsHamburgerOpen={setIsHamburgerOpen}
            classList={`transition-all duration-300 text-xs text-black pl-10 pr-0 pb-2 pt-3 first:pl-0 block hover:text-pink-500 uppercase flex items-center justify-start lg:justify-end ${classList}`}
          >
            <svg className="w-5 h-5 mr-2.5 -mt-1" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C8.337 0 7 1.337 7 3s1.337 1.5 3 1.5 3 .163 3-1.5-1.337-3-3-3Zm0 3c-.711 0-1.286.711-1.286 0S9.29 1.714 10 1.714c.711 0 1.286.575 1.286 1.286 0 .711-.575 0-1.286 0Z" fill="#E6007D"/>
              <path stroke="#E6007D" strokeWidth="2" d="M7 4h6v4H7zM1 12h18v6H1z"/>
              <path d="M3 14h2v2H3v-2ZM6 14h2v2H6v-2ZM9 14h2v2H9v-2ZM12 14h2v2h-2v-2ZM15 14h2v2h-2v-2Z" fill="#E6007D"/>
            </svg>
            {button.name}
          </ActiveLink>
        </div>
      ) : (
        <React.StrictMode>
          {isDesktop ? (
            <div className="flex justify-evenly lg:block mt-2 lg:mt-0">
              {RewardsMenu[0].items.map(item => (
                <RewardsNavigation
                  punchhUserCookie={punchhUserCookie}
                  key={uid(16)}
                  classList={`absolute top-full left-0 ${classList}`}
                  item={item}
                />
              ))}
            </div>
          ) : (
            <div className="block mt-0">
              {RewardsMenu[0].items.map(item => (
                <RewardsNavigationMobile
                  isDesktop={isDesktop}
                  punchhUserCookie={punchhUserCookie}
                  key={uid(16)}
                  classList={`absolute top-full left-0 ${classList}`}
                  item={item}
                />
              ))}
            </div>
          )}
        </React.StrictMode>
      )}
    </React.StrictMode>
  )
}

export default LogOutAccountButton
